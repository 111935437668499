import React from 'react'

import OurStoryBG from '../../assets/images/TOP_our_story.png'
import img2 from '../../assets/images/img2.png'
import img1 from '../../assets/images/img1.png'
import img3 from '../../assets/images/img3.png'
import owner from '../../assets/images/owner.jpg'

const OurStory = () => {
  return (
    <>
      <div className='fluid'>
        <div className='position-relative overflow-hidden'>
          <img src={OurStoryBG} alt='Contact Us' className='img-full' />
          <h3 className='text-black font-secondary position-absolute bottom-0 ps-3 d-block d-sm-none'>
            OUR STORY
          </h3>
        </div>
      </div>

      <div className='container text-center px-7'>
        <h1 className='text-black pt-5 pb-2 font-secondary'>ABOUT PANDA CUP</h1>
        <div className='ratio ratio-16x9'>
          <iframe
            src='https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F1008850480200355%2F&show_text=false&&appId'
            title='The panda cup story'
            allowFullScreen
          />
        </div>
        <div className='pb-5'>
          <p className='pt-4'>
            Welcome to Panda Cup, an independent artisan café, specializing in
            teas and bubble drinks in London. Whether you are a first-time
            drinker, a seasoned connoisseur, or a casual weekend consumer, we
            are passionate about crafting the perfect cuppa for you using the
            best ingredients and drinks.
          </p>
          <p>
            We offer a wide range of specialty coffees, teas, and bubbles,
            including award-winning teas. Our delicious treats are baked
            in-house, and we also serve delicious made-to-order food to satisfy
            your cravings.
          </p>
          <p>
            Come and enjoy a cozy atmosphere, sip on your favorite beverage, and
            indulge in our mouth-watering treats. We take pride in providing a
            personalized experience that caters to your taste buds. Our team of
            experts is always happy to assist you in choosing the perfect drink
            that suits your preferences.
          </p>
          <p>
            Visit us at Panda Cup and experience the perfect cuppa that will
            keep you coming back for more.
          </p>
        </div>
      </div>
      <section className='container imageSection'>
        <div className='row pb-5 gx-3 flex-nowrap'>
          <div className='col-sm-4'>
            <img src={img2} alt='Contact Us' className='img-fluid w-100' />
          </div>
          <div className='col-sm-4 '>
            <img src={img1} alt='Contact Us' className='img-fluid w-100' />
          </div>
          <div className='col-sm-4'>
            <img src={img3} alt='Contact Us' className='img-fluid w-100' />
          </div>
        </div>
      </section>
      <div className='background-image--contactFormBg'>
        <div className='container px-7'>
          <h1 className='text-black pt-5 pb-3 text-center font-secondary'>
            OUR FOUNDER
          </h1>
          <div className='text-center pb-5'>
            <p>
              Panda Cup is a unique bubble tea and latte art bar located in the
              vibrant city of London. Founded by Kunthea Seng in 2019, our bar
              offers a wide range of delicious and flavoursome treats that are
              sure to delight your taste buds.
            </p>
            <p>
              At Panda Cup, we take pride in using only the finest quality
              ingredients in our drinks and cakes. This means that every sip and
              bite is not only tasty but also wholesome.
            </p>
            <p>
              Whether you&apos;re looking for a refreshing bubble tea, a rich
              and creamy latte, or a sweet treat to satisfy your cravings, Panda
              Cup has got you covered. Our drinks are made with care and
              attention to detail, ensuring that every cup is a masterpiece.
            </p>
            <p>
              So, the next time you&apos;re in the mood for something vibrant,
              quirky, and delicious, come and visit us at Panda Cup. We promise
              you won&apos;t be disappointed!
            </p>
          </div>
        </div>
      </div>
      <div
        className='container text-center mx-auto overflow-hidden'
        style={{ maxHeight: '500px' }}
      >
        <img src={owner} alt='Our Founder' className='img-fluid my-5' />
      </div>
      <div className='container text-center px-7'>
        <h1 className='text-black pt-2 font-secondary'>IN THE NEWS</h1>

        <a
          href='https://www.mylondon.news/special-features/london-coffee-shop-customers-can-24933026'
          title='The London coffee shop where customers can enjoy amazing latte art and delicious bubble tea flavours'
          target='_blank'
          rel='noreferrer'
        >
          <span className='font-secondary'>MYLONDON</span> - The London coffee
          shop where customers can enjoy amazing latte art and delicious bubble
          tea flavours
        </a>
      </div>
    </>
  )
}

export default OurStory
